<template>
  <div class="profile container is-fullhd">
    <h1 class="title">{{title}}</h1>
    <CourseCrumb v-if="loaded" :course="lesson.course" :skill="lesson.skill" :lesson="lesson" />

    <section class="columns" v-if="loaded">
      <div class="column is-12">
        <Sketchboard v-if="loaded" :lesson="lesson" :parent="remixSketch" />
      </div>
    </section>

    <template v-if="sketches.length">
      <h2 class="subtitle mt-6">Previous sketches</h2>
      <CarouselList
        v-model="ps"
        :data="sketches"
        :items-to-show="3"
        :repeat="true"
        :arrow="true"
        :arrow-both="true"
        :arrow-hover="true">
        <template slot="item" slot-scope="props">
          <SketchCard :sketch="props.list" :content="false" />
        </template>
      </CarouselList>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import API from '@/services/api';
import Sketchboard from '@/components/Sketchboard.vue';
import CourseCrumb from '@/components/CourseCrumb.vue';
import CarouselList from '@/components/CarouselList.vue';
import SketchCard from '@/components/SketchCard.vue';

export default {
  name: 'Lesson',
  components: {
    Sketchboard,
    CourseCrumb,
    CarouselList,
    SketchCard,
  },
  data() {
    return {
      loaded: false,
      lesson: null,

      remixSketch: null,

      ps: 0,
      sketches: [],
    };
  },
  async created() {
    this.id = this.$route.params.lessonId;
    this.courseUrl = this.$route.params.courseUrl;
    this.skillUrl = this.$route.params.skillUrl;
    this.url = this.$route.params.lessonUrl;

    const remixId = parseInt(this.$route.query.remix, 10);
    if (!Number.isNaN(remixId) && remixId > 0) {
      await this.getRemixSketch(remixId);
      this.$gtag.event('remix', { event_category: 'engagement' });
    }
    this.getLesson();
  },
  computed: {
    ...mapGetters({
      user: 'session/user',
    }),
    title() {
      if (this.lesson) return this.lesson.name;
      if (this.url) {
        return this.url[0].toUpperCase() + this.url.substr(1);
      }
      return 'Lesson';
    },
  },
  methods: {
    async getLesson() {
      try {
        let res;
        if (this.id) {
          res = await API.lessons.get(
            this.id,
            { include: 'course,skill' },
          );
        } else {
          res = await API.lessons.find(
            this.courseUrl, this.skillUrl, this.url,
            { include: 'course,skill' },
          );
        }
        this.lesson = res.data.lesson;
        this.loaded = true;
      } catch (e) {
        const error = API.handleError(e);
        if (error) this.$toasted.error(error.message, { duration: 2000 });
      }
      this.getPreviousSketches();
    },
    async getPreviousSketches() {
      try {
        const { data } = await API.sketches.listForUserLesson(this.lesson.id);
        this.sketches = data.sketches;
      } catch (e) {
        const error = API.handleError(e);
        if (error) this.$toasted.error(error.message, { duration: 2000 });
      }
    },
    async getRemixSketch(id) {
      try {
        const { data } = await API.sketches.get(id);
        this.remixSketch = data.sketch;
      } catch (e) {
        const error = API.handleError(e);
        if (error) {
          if (error.status === 404) {
            this.$toasted.error(
              'Could not load sketch for remixing, going ahead with the standard reference!',
              { duration: 2000 },
            );
          } else {
            this.$toasted.error(error.message, { duration: 2000 });
          }
        }
      }
    },
  },
};
</script>
