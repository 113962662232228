<template>
  <div class="signup container is-fullhd">
    <h1 class="title">
      Let's start with
      <span v-if="loaded">"{{lesson.name}}"</span>
    </h1>

    <h2 class="subtitle mt-4 mb-0">Before you dive in, here are some tips:</h2>
    <ul class="tips mb-5">
      <li>
        Every lesson has a <strong>time limit</strong>,
        you must sketch for <strong>at least 10%</strong> of the time.
        You can then <strong>end the sketch early</strong> if you wish, or keep going.
      </li>
      <li>
        The sketch <strong>reference</strong> is only there for <strong>inspiration</strong>,
        feel free to do your own take on the lesson subject &mdash; progress is tracked based on
        time spent sketching, not based on similarity to the reference image.
        You can toggle the reference to be under or over your sketch,
        or <strong>disable</strong> it entirely.
      </li>
      <li>
        Once you complete your first sketch,
        you can then create a <strong>free account</strong> and keep track of your progress.
      </li>
    </ul>

    <section class="columns" v-if="loaded">
      <div class="column is-12">
        <Sketchboard v-if="loaded" :lesson="lesson" />
      </div>
    </section>
  </div>
</template>

<script>
import API from '@/services/api';
import Sketchboard from '@/components/Sketchboard.vue';

export default {
  name: 'Signup',
  components: {
    Sketchboard,
  },
  data() {
    return {
      loaded: false,
      lesson: null,
    };
  },
  created() {
    if (this.$route.query.id) {
      const id = parseInt(this.$route.query.id, 10);
      if (!Number.isNaN(id)) this.id = id;
    }
    this.getLesson();
  },
  methods: {
    async getLesson() {
      try {
        const { data } = await API.lessons.trial(this.id);
        this.lesson = data.lesson;
        this.loaded = true;
      } catch (e) {
        const error = API.handleError(e);
        if (error) this.$toasted.error(error.message, { duration: 2000 });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
ul.tips {
  max-width: 1000px;
  padding-left: 20px;
  list-style-type: disc;
}
</style>
