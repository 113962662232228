<template>
  <div class="profile container is-fullhd">
    <h1 class="title">
      Practice
      <span v-if="loaded">"{{lesson.name}}"</span>
    </h1>
    <CourseCrumb v-if="loaded" :course="lesson.course" :skill="lesson.skill" :lesson="lesson" />

    <section class="columns" v-if="loaded">
      <div class="column is-12">
        <Sketchboard v-if="loaded" :lesson="lesson" />
      </div>

      <!-- <Sidebar class="column" :user="user" /> -->
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import API from '@/services/api';
// import Sidebar from '@/components/Sidebar.vue';
import Sketchboard from '@/components/Sketchboard.vue';
import CourseCrumb from '@/components/CourseCrumb.vue';

export default {
  name: 'Practice',
  components: {
    // Sidebar,
    Sketchboard,
    CourseCrumb,
  },
  data() {
    return {
      loaded: false,
      lesson: null,
    };
  },
  created() {
    this.getLesson();
  },
  computed: {
    ...mapGetters({
      user: 'session/user',
    }),
  },
  methods: {
    async getLesson() {
      try {
        const res = await API.lessons.practice({ include: 'course,skill' });
        this.lesson = res.data.lesson;
        this.loaded = true;
      } catch (e) {
        const error = API.handleError(e);
        if (error) this.$toasted.error(error.message, { duration: 2000 });
      }
    },
  },
};
</script>
