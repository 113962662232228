<template>
  <nav class="breadcrumb" aria-label="breadcrumbs">
    <ul>
      <li :class="{'is-active': $route.path == courseUrl}" v-if="course">
        <router-link :to="courseUrl">{{course.name}}</router-link>
      </li>
      <li
        :class="{'is-active': $route.path == skillUrl || $route.params.skillId === skillId }"
        v-if="skill">
        <router-link :to="skillUrl">{{skill.name}}</router-link>
      </li>
      <li
        :class="{'is-active': lessonActive}"
        v-if="lesson">
        <router-link :to="lessonUrl">{{lesson.name}}</router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'CourseCrumb',
  props: {
    course: {
      type: Object,
    },
    skill: {
      type: Object,
    },
    lesson: {
      type: Object,
    },
  },
  computed: {
    courseUrl() {
      if (!this.course) return '';
      return `/course/${this.course.url}`;
    },
    skillUrl() {
      if (!this.skill) return '';
      return `${this.courseUrl}/${this.skill.url}`;
    },
    lessonUrl() {
      if (!this.lesson) return '';
      return `${this.skillUrl}/${this.lesson.url}`;
    },
    skillId() {
      return this.skill ? this.skill.id.toString() : '-1';
    },
    lessonId() {
      return this.lesson ? this.lesson.id.toString() : '-1';
    },

    lessonActive() {
      const active = (
        this.$route.path === this.lessonUrl
        || this.$route.path === '/practice'
        || this.$route.params.lessonId === this.lessonId);
      return active;
    },
  },
};
</script>
